import {
  Box,
  GridCol,
  GridRow,
  Skeleton,
  SkeletonButton,
  SkeletonPageSearch,
  SkeletonH3,
} from 'ds4-beta'

const ProductListSkeleton = () => {
  return (
    <Box padding={[6]}>
      <GridRow padding={false}>
        <GridCol sm={12}>
          <GridRow padding={false}>
            <GridCol lg={9} md={8} sm={7}>
              <SkeletonH3 width={'150px'} />
            </GridCol>
            <GridCol lg={3} md={4} sm={5}>
              <Box
                flex={{ alignItems: 'center', justifyContent: 'end' }}
                gap={2}
              >
                <SkeletonButton />
                <SkeletonButton />
              </Box>
            </GridCol>
          </GridRow>
        </GridCol>
        <GridCol sm={12}>
          <Box>
            <SkeletonPageSearch />
          </Box>
          <Box margin={{ top: 3 }} flex={{ justifyContent: 'start' }} gap={1}>
            <SkeletonButton width={'75px'} />
            <SkeletonButton />
            <SkeletonButton width={'100px'} />
          </Box>
        </GridCol>
        <GridCol sm={12}>
          <Skeleton height='600px' width='100%' />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ProductListSkeleton
