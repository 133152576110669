export const FEATURE_FLAGS = {
  UI_MULTICHANNEL: 'ui-multichannel',
}

export const featureFlagDomainMapping = {
  dev02: 'dev',
  stg02: 'stage',
  sandbox: 'sandbox',
  prod01: 'prod',
}

export const featureFlagEnvMapping = {
  dev02: 'development',
  stg02: 'staging',
  sandbox: 'uat',
  prod01: 'production',
}

export const MAX_CHANNEL_PILLS_TO_DISPLAY = 2

export const PARTIALLY_COMPLETED_WITH_ERRORS = 'Partially completed with errors'
