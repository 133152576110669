import { lazy, Suspense, useEffect } from 'react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { CopilotUIProvider } from 'ds4-beta'
import ProductListSkeleton from 'layouts/list-page/skeleton'
import { removeUnleashClient, startUnleashClient } from 'lib/utils/feature-flag'
import { BrowserRouter } from 'react-router-dom'

let client = new QueryClient()

const App = lazy(() => import('./app.component'))

export default function Root(): JSX.Element {
  useEffect(() => {
    startUnleashClient()

    return removeUnleashClient
  }, [])

  // / wrap browser
  return (
    <>
      <Suspense fallback={<ProductListSkeleton />}>
        <CopilotUIProvider>
          <QueryClientProvider client={client}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </CopilotUIProvider>
      </Suspense>
    </>
  )
}
