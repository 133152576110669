/* eslint-disable */
import {
  FEATURE_FLAGS,
  featureFlagEnvMapping,
  featureFlagDomainMapping,
} from 'lib/constants/general-config'

import { UnleashClient } from 'unleash-proxy-client'
import { getTenantId } from './helper'

const proxyEnv = process.env.STAGE || 'dev02'
export const config = {
  url: `https://${featureFlagDomainMapping[proxyEnv]}.foundation.feature.fabric.inc/proxy`,
  clientKey: process.env.FEATURE_FLAG_CLIENT_KEY,
  appName: 'pim',
  environment: featureFlagEnvMapping[proxyEnv],
  disableRefresh: true,
}

let client

export const startUnleashClient = () => {
  if (client) {
    return
  }

  client = new UnleashClient(config as any)
  client.start()

  const tenantId = getTenantId()
  client.updateContext({ tenantId })
}

export const removeUnleashClient = () => {
  client = null
}

export const isFeatureActive = ({ flagName }) => {
  if (!client) {
    startUnleashClient()
  }

  const flag = client.isEnabled(flagName)
  return flag
}
