import { IFormatDateOptions } from 'types/utils'
import moment from 'moment-timezone'
import { isEmpty } from 'lodash'
// import { format } from 'date-fns'

// type optionsTypes = {
//   day?: string
//   month?: string
//   hour?: string
//   minute?: string
// }

export const formatDate = (value) => {
  const options: IFormatDateOptions = {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  }
  return new Date(value).toLocaleDateString('en-US', options)
}

export const timeSince = (date) => {
  var ms = new Date().getTime() - new Date(date).getTime()
  var seconds = Math.floor(ms / 1000)
  var minutes = Math.floor(seconds / 60)
  var hours = Math.floor(minutes / 60)
  var days = Math.floor(hours / 24)
  var months = Math.floor(days / 30)
  var years = Math.floor(months / 12)
  if (ms === 0) {
    return 'Just now'
  }
  if (seconds < 60) {
    return seconds + ' seconds ago'
  }
  if (minutes < 60) {
    return minutes + ' mins ago'
  }
  if (hours < 24) {
    return hours + ' hours ago'
  }
  if (days < 30) {
    return days + ' days ago'
  } else {
    return formatDate(date)
  }
  // if (months < 12) {
  //     return months + ' months Ago';
  // } else {
  //   return years + ' years Ago';
  // }
}

export const getLastUpdated = (date) =>
  moment.tz(date, moment.tz.guess()).format('MMM. DD, YYYY, h:mm A z')

export const getFormatedDate = (date) =>
  moment.tz(date, moment.tz.guess()).format()

export const getTableCaption = ({ totalSize, currentPage }) => {
  return `Showing ${10 * currentPage + 1} - ${
    10 * (currentPage + 1) > totalSize ? totalSize : 10 * (currentPage + 1)
  } of ${totalSize}`
}

export const getTenantId = () => {
  return window.localStorage.isLoggedInWithIdV2
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account')
}

export const getStage = (isBrowser) => {
  let stage =
    process.env.STAGE ??
    ((isBrowser && new URL(window.location.origin).hostname?.split('.')[0]) ||
      'dev02')
  if (stage && stage === 'live') stage = 'prod01'
  return stage
}

export const checkIfValueIsBoolean = (value) => {
  return value === 'true' || value === 'false'
}

export const dateFormatHandler = (date) => {
  return date ? moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY') : ''
}

export const getSortPayload = (sortData) => {
  return `${sortData.direction === 'DESC' ? '-' : '+'}${sortData.field}`
}

export const getXSiteContext = (isBrowser) => {
  // If STAGE env var is present then pick it from there else fallback to url
  let stage =
    process.env.STAGE ??
    ((isBrowser && new URL(window.location.origin).hostname?.split('.')[0]) ||
      'dev02')
  if (stage && stage === 'live') stage = 'prod01'

  const xSiteContext = JSON.stringify({
    date: new Date().toISOString(),
    channel: 12,
    source: 'copilot',
    /*
      If logged in with identity v2 then use "accountId" from session storage
      otherwise use "account"
    */
    account: getTenantId(),
    stage: stage,
  })

  return xSiteContext
}

export const getSortedChannels = (channels) => {
  const mappedChannel = [] as any
  channels.forEach((channel) => {
    channel?.isDefault
      ? mappedChannel?.unshift(channel)
      : mappedChannel?.push(channel)
  })
  return [
    mappedChannel[0],
    ...mappedChannel
      ?.slice(1)
      .sort((a: any, b: any) => a?.name.localeCompare(b.name)),
  ]
}
